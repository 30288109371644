import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	socialIcons: {
		display: "flex",
		flexWrap: "wrap",
		columnGap: theme.spacing(1),
		gap: theme.spacing(1)
	},
	item: {
		"& a": {
			alignItems: "center",
			background: theme.palette.primary.main,
			borderRadius: theme.shape.borderRadius,
			display: "flex",
			height: 56,
			justifyContent: "center",
			transition: "background 250ms ease",
			width: 56,
			"&:hover": {
				background: theme.palette.primary.dark
			}
		}
	},
	icon: {
		"& svg path": {
			fill: `${theme.palette.common.white} !important`
		}
	}
}));

export default styles;
