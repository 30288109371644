import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	lang: {
		alignItems: "center",
		cursor: "pointer",
		display: "flex",
		height: theme.spacing(6),
		justifyContent: "center",
		position: "relative",
		textTransform: "uppercase",
		width: theme.spacing(6),
		zIndex: 90
	},
	langOpen: {
		color: theme.palette.primary.main
	}
}));

export default styles;
