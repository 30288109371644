import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	level2: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(4),
		height: "100%",
		overflowX: "hidden",
		overflowY: "auto",
		padding: "151px 0 0"
	},
	back: {
		alignItems: "center",
		cursor: "pointer",
		display: "flex",
		gap: theme.spacing(1.5),
		margin: theme.spacing(0, 2)
	},
	title: {
		color: theme.palette.primary.dark,
		margin: theme.spacing(0, 2)
	},
	item: {
		margin: theme.spacing(0, 2)
	},
	link: {
		alignItems: "center",
		color: theme.palette.text.primary,
		display: "flex",
		justifyContent: "space-between"
	},
	linkSelected: {
		color: theme.palette.primary.main
	}
}));

export default styles;
