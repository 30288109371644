import React, { useContext } from "react";
import clsx from "clsx";
import UiContext from "@UiContext";

import styles from "./Container.styles";

const Container = props => {
	const {
		anchor,
		background,
		children,
		className,
		maxWidth: _maxWidth,
		noPadding = false,
		style,
		tag
	} = props;
	const [ui] = useContext(UiContext);
	const { _settings } = ui;
	const { global: { container: { maxWidth: globalMaxWidth = 1920 } = {} } = {} } =
		_settings || {};
	const { tag: anchorTag } = anchor || {};
	const maxWidth = _maxWidth || (noPadding ? 1872 : globalMaxWidth);
	const css = styles(background, maxWidth || globalMaxWidth, noPadding);

	return (
		<div
			className={clsx(
				css.container,
				"mco-view-component-container",
				className,
				tag ? `mco-view-component-container-${tag}` : ""
			)}
			data-anchor={anchorTag}
			style={style}
		>
			{children}
		</div>
	);
};

export default Container;
