import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import isEmpty from "lodash/isEmpty";
import useFindMenuGroup from "@micado-digital/react-ui/hooks/useFindMenuGroup";
import duplicateParentAsFirstChild from "@micado-digital/react-ui/utils/duplicateParentAsFirstChild";
import Level1 from "./Level1";
import Level2 from "./Level2";
import Level3 from "./Level3";
import Level4 from "./Level4";
import SocialIcons from "@Components/views/SocialIcons";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./MobileMenu.styles";

const MobileMenu = () => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	const { lang } = pageData;
	const menuItems = useFindMenuGroup("Hauptmenü");
	const [level2, setLevel2] = useState({});
	const { level2Items, level1Title } = level2;
	const [level3, setLevel3] = useState({});
	const { level3Items, level2Title } = level3;
	const [level4, setLevel4] = useState({});
	const { level4Items, level3Title } = level4;

	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	useEffect(() => {
		duplicateParentAsFirstChild(menuItems, "");
	}, [menuItems, lang, l]);

	const handleShowLevel2 = (title, items) => {
		setLevel2({
			level2Items: items,
			level1Title: title
		});
	};

	const handleBackLevel2 = () => {
		setLevel2({});
	};

	const handleShowLevel3 = (title, items) => {
		setLevel3({
			level3Items: items,
			level2Title: title
		});
	};

	const handleBackLevel3 = () => {
		setLevel3({});
	};

	const handleShowLevel4 = (title, items) => {
		setLevel4({
			level4Items: items,
			level3Title: title
		});
	};

	const handleBackLevel4 = () => {
		setLevel4({});
	};

	return (
		<div className={clsx(css.mobileMenu, "mco-view-component-header-mobile-menu")}>
			{isEmpty(level2) && (
				<Level1 items={menuItems} lang={lang} onItemClick={handleShowLevel2} />
			)}
			{!isEmpty(level2) && isEmpty(level3) && (
				<Level2
					items={level2Items}
					lang={lang}
					onBackClick={handleBackLevel2}
					onItemClick={handleShowLevel3}
					prevTitle={level1Title}
				/>
			)}
			{!isEmpty(level3) && isEmpty(level4) && (
				<Level3
					items={level3Items}
					lang={lang}
					onBackClick={handleBackLevel3}
					onItemClick={handleShowLevel4}
					prevTitle={level2Title}
				/>
			)}
			{!isEmpty(level4) && (
				<Level4
					items={level4Items}
					lang={lang}
					onBackClick={handleBackLevel4}
					prevTitle={level3Title}
				/>
			)}
			<SocialIcons />
		</div>
	);
};

export default MobileMenu;
