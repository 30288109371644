import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	hamburger: {
		margin: theme.spacing(0, -1, 0, 0),
		position: "relative",
		zIndex: 100
	}
}));

export default styles;
