import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { motion } from "framer-motion";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import Local from "@Local";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Level2.styles";

const ReactLink = loadable(() => import("@micado-digital/react-link/ReactLink"));
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));
const Typography = loadable(() => import("@micado-digital/react-ui/components/Typography"));

const Level2 = ({ items, lang, onBackClick, onItemClick, prevTitle }) => {
	const css = styles();
	const $level2 = useRef(null);

	useEffect(() => {
		disableBodyScroll($level2.current);

		return () => {
			clearAllBodyScrollLocks();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	return (
		<div
			className={clsx(css.level2, "mco-view-component-header-mobile-menu-level2")}
			ref={$level2}
		>
			<motion.div
				className={clsx(css.back, "mco-view-component-header-mobile-menu-level2__back")}
				onClick={onBackClick}
				initial={{ opacity: 0, x: 20 }}
				animate={{ opacity: 1, x: 0 }}
				transition={{ delay: 0.3, duration: 0.5 }}
			>
				<ReactSVGIcon size={16} src="/img/icons/chevron-left.svg" />
				<Typography variant="label-small">
					<Local identifier="menu.back" l={l} />
				</Typography>
			</motion.div>
			<motion.div
				className={clsx(css.title, "mco-view-component-header-mobile-menu-level2__title")}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.5 }}
			>
				<Typography dangerouslySetInnerHTML={{ __html: prevTitle }} variant="headline-small" />
			</motion.div>
			{items?.map(item => {
				const { id, items, link, selected, target, title } = item;

				return (
					<motion.div
						className={clsx(css.item, "mco-view-component-header-mobile-menu-level2-item")}
						key={id}
						initial={{ opacity: 0, x: -20 }}
						animate={{ opacity: 1, x: 0 }}
						transition={{ duration: 0.5 }}
					>
						<ReactLink
							className={clsx(
								css.link,
								"mco-view-component-header-mobile-menu-level2-item__link",
								{
									[css.linkSelected]: selected,
									"mco-view-component-header-mobile-menu-level2-item__link--selected": selected
								}
							)}
							onClick={e => {
								if (items && items.length > 0) {
									e.preventDefault();

									onItemClick(title, items);
								}
							}}
							target={target}
							to={link}
						>
							<Typography dangerouslySetInnerHTML={{ __html: title }} variant="body-medium" />
							{items && <ReactSVGIcon src="/img/icons/chevron-right.svg" />}
						</ReactLink>
					</motion.div>
				);
			})}
		</div>
	);
};

export default Level2;
