export const theme = {
	palette: {
		primary: {
			light: "#c65470",
			main: "#C4122F",
			dark: "#79001d"
		},
		secondary: {
			light: "#548eb5",
			main: "#005790",
			dark: "#023059"
		},
		background: {
			default: "#ffffff",
			header: "#ffffff",
			menu: "#e3e5e8",
			footer: "#f7f7f8",
			light: "#f7f7f8",
			dark: "#79001d"
		},
		text: {
			primary: "#333333",
			secondary: "#666666",
			disabled: "#bfbfbf",
			hint: "#bfbfbf"
		},
		grey: {
			50: "#f7f7f8",
			100: "#e3e5e8",
			200: "#cdd1d5",
			300: "#acb2b9",
			400: "#9199a1",
			500: "#75808a",
			600: "#5e666e",
			700: "#464d53",
			800: "#2f3337",
			900: "#171a1c",
			A100: "#cdd1d5",
			A200: "#acb2b9",
			A400: "#2f3337",
			A700: "#5e666e"
		},
		common: {
			white: "#ffffff",
			black: "#000000"
		},
		info: {
			light: "#77b6da",
			main: "#0175b9",
			dark: "#01446c"
		},
		success: {
			light: "#a4bf7a",
			main: "#70992e",
			dark: "#477301"
		},
		warning: {
			light: "#ffde77",
			main: "#ffc105",
			dark: "#d19e05"
		},
		error: {
			light: "#f08080",
			main: "#d50b0b",
			dark: "#8b0101"
		},
		divider: "#cccccc",
		shape: {
			borderRadius: ""
		}
	},
	typography: {
		fontFamily: "Open Sans",
		"display-large": {
			fontFamily: "Montserrat",
			fontWeight: 400,
			fontSize: "clamp(34px, 6vw, 56px)",
			letterSpacing: -0.56,
			lineHeight: 1.1
		},
		"display-medium": {
			fontFamily: "Montserrat",
			fontWeight: 400,
			fontSize: "clamp(32px, 5vw, 48px)",
			lineHeight: 1.1
		},
		"display-small": {
			fontFamily: "Montserrat",
			fontWeight: 400,
			fontSize: "clamp(28px, 4vw, 42px)",
			lineHeight: 1.2
		},
		"headline-large": {
			fontFamily: "Montserrat",
			fontWeight: 400,
			fontSize: "clamp(28px, 4vw, 36px)",
			letterSpacing: 0,
			lineHeight: 1.3
		},
		"headline-medium": {
			fontFamily: "Montserrat",
			fontWeight: 400,
			fontSize: 28,
			letterSpacing: 0,
			lineHeight: "37px"
		},
		"headline-small": {
			fontFamily: "Montserrat",
			fontWeight: 400,
			fontSize: 24,
			letterSpacing: 0,
			lineHeight: "32px"
		},
		"title-large": {
			fontFamily: "Montserrat",
			fontWeight: 500,
			fontSize: 24,
			letterSpacing: 0,
			lineHeight: "32px"
		},
		"title-medium": {
			fontFamily: "Montserrat",
			fontWeight: 500,
			fontSize: 20,
			letterSpacing: 0,
			lineHeight: "26px"
		},
		"title-small": {
			fontFamily: "Montserrat",
			fontWeight: 400,
			fontSize: 16,
			letterSpacing: 4,
			textTransform: "uppercase",
			lineHeight: "21px"
		},
		"body-large": {
			fontFamily: "Open Sans",
			fontWeight: 400,
			fontSize: 20,
			letterSpacing: 0.2,
			lineHeight: "36px"
		},
		"body-medium": {
			fontFamily: "Open Sans",
			fontWeight: 400,
			fontSize: 18,
			letterSpacing: 0.18,
			lineHeight: "29px"
		},
		"body-small": {
			fontFamily: "Open Sans",
			fontWeight: 400,
			fontSize: 14,
			letterSpacing: 0.7000000000000001,
			lineHeight: "23px"
		},
		"label-large": {
			fontFamily: "Montserrat",
			fontWeight: 500,
			fontSize: 20,
			letterSpacing: 0.2,
			textTransform: "uppercase",
			lineHeight: "20px"
		},
		"label-medium": {
			fontFamily: "Montserrat",
			fontWeight: 500,
			fontSize: 16,
			letterSpacing: 0.16,
			textTransform: "uppercase",
			lineHeight: "21px"
		},
		"label-small": {
			fontFamily: "Montserrat",
			fontWeight: 500,
			fontSize: 14,
			letterSpacing: 0.42,
			textTransform: "uppercase",
			lineHeight: "19px"
		},
		h1: {
			fontFamily: "Montserrat",
			fontWeight: 400,
			fontSize: "clamp(34px, 6vw, 56px)",
			letterSpacing: -0.56,
			lineHeight: 1.1
		},
		h2: {
			fontFamily: "Montserrat",
			fontWeight: 400,
			fontSize: "clamp(32px, 5vw, 48px)",
			letterSpacing: 0,
			lineHeight: 1.1
		},
		h3: {
			fontFamily: "Montserrat",
			fontWeight: 400,
			fontSize: "clamp(28px, 4vw, 42px)",
			letterSpacing: 0,
			lineHeight: 1.2
		},
		h4: {
			fontFamily: "Montserrat",
			fontWeight: 400,
			fontSize: "clamp(28px, 4vw, 36px)",
			letterSpacing: 0,
			lineHeight: 1.3
		},
		h5: {
			fontFamily: "Montserrat",
			fontWeight: 400,
			fontSize: 28,
			letterSpacing: 0,
			lineHeight: "37px"
		},
		h6: {
			fontFamily: "Montserrat",
			fontWeight: 400,
			fontSize: 24,
			letterSpacing: 0,
			lineHeight: "32px"
		},
		subtitle1: {
			fontFamily: "Open Sans",
			fontWeight: 400,
			fontSize: 20,
			letterSpacing: 0.2,
			lineHeight: "36px"
		},
		subtitle2: {
			fontFamily: "Open Sans",
			fontWeight: 400,
			fontSize: 18,
			letterSpacing: 0.18,
			lineHeight: "29px"
		},
		overline: {
			fontFamily: "Open Sans",
			fontWeight: 400,
			fontSize: 14,
			letterSpacing: 0.7000000000000001,
			lineHeight: "23px"
		},
		"button-large": {
			fontFamily: "Montserrat",
			fontWeight: 500,
			fontSize: 20,
			letterSpacing: 0.2,
			textTransform: "uppercase",
			lineHeight: "20px"
		},
		"button-medium": {
			fontFamily: "Montserrat",
			fontWeight: 500,
			fontSize: 16,
			letterSpacing: 0.16,
			textTransform: "uppercase",
			lineHeight: "21px"
		},
		"button-small": {
			fontFamily: "Montserrat",
			fontWeight: 500,
			fontSize: 14,
			letterSpacing: 0.42,
			textTransform: "uppercase",
			lineHeight: "19px"
		}
	},
	shape: {
		borderRadius: 4
	},
	overrides: {
		MuiButton: {
			root: {
				boxShadow: "none !important",
				fontFamily: "'Montserrat' !important",
				fontWeight: 500,
				minHeight: 48
			},
			sizeLarge: {
				fontSize: 20,
				minHeight: 56
			},
			sizeSmall: {
				fontSize: 14,
				minHeight: 40
			},
			text: {
				"&:hover": {
					background: "none !important"
				},
				"& .MuiTouchRipple-root": {
					display: "none"
				}
			}
		}
	},
	props: {
		MuiButton: {
			disableElevation: true
		},
		MuiButtonGroup: {
			disableElevation: true
		}
	}
};
