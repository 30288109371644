import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { motion } from "framer-motion";

import styles from "./Level1.styles";

const ReactLink = loadable(() => import("@micado-digital/react-link/ReactLink"));
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));
const Typography = loadable(() => import("@micado-digital/react-ui/components/Typography"));

const Level1 = ({ items, onItemClick }) => {
	const css = styles();
	const $level1 = useRef(null);

	useEffect(() => {
		disableBodyScroll($level1.current);

		return () => {
			clearAllBodyScrollLocks();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			className={clsx(css.level1, "mco-view-component-header-mobile-menu-level1")}
			ref={$level1}
		>
			{items?.map(item => {
				const { id, items, link, selected, target, title } = item;

				return (
					<motion.div
						className={clsx(css.item, "mco-view-component-header-mobile-menu-level1-item")}
						key={id}
						initial={{ opacity: 0, x: -20 }}
						animate={{ opacity: 1, x: 0 }}
						transition={{ duration: 0.5 }}
					>
						<ReactLink
							className={clsx(
								css.link,
								"mco-view-component-header-mobile-menu-level1-item__link",
								{
									[css.linkSelected]: selected,
									"mco-view-component-header-mobile-menu-level1-item__link--selected": selected
								}
							)}
							onClick={e => {
								if (items && items.length > 0) {
									e.preventDefault();

									onItemClick(title, items);
								}
							}}
							target={target}
							to={link}
						>
							<Typography
								dangerouslySetInnerHTML={{ __html: title }}
								variant="headline-small"
							/>
							{items && <ReactSVGIcon src="/img/icons/chevron-right.svg" />}
						</ReactLink>
					</motion.div>
				);
			})}
		</div>
	);
};

export default Level1;
