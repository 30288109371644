import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import isEmpty from "lodash/isEmpty";
import clsx from "clsx";
import axios from "axios";
import { motion } from "framer-motion";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Specifications.styles.js";

const Alert = loadable(() => import("@material-ui/lab/Alert"));
const Button = loadable(() => import("@material-ui/core/Button"));
const Container = loadable(() => import("@Components/views/Container"));
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));
const ReactTable01 = loadable(() => import("@micado-digital/react-table/ReactTable01"));
const Typography = loadable(() => import("@micado-digital/react-ui/components/Typography"));

const Specifications = ({ elements, headline, lang }) => {
	const { REACT_APP_PATH } = process.env;
	const css = styles();
	const [active, setActive] = useState("");
	const [groups, setGroups] = useState([]);
	const [isVisible, setIsVisible] = useState(true);
	const [tableIDs, setTableIDs] = useState([]);
	const [tables, setTables] = useState([]);
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	useEffect(() => {
		elements?.forEach((element, index) => {
			const { elements, textcontent: { items = [] } = {} } = element;
			const identifier = items?.find(item => item?.name === "valIdentifier")?.text;
			const media = elements?.find(item => item?.tag === "valTable")?.media;

			if (identifier && index === 0) {
				setActive(identifier);
			}

			if (identifier && media) {
				setGroups(prev => [...new Set([...prev, identifier])]);
			}

			if (media) {
				const { id } = media?.[0];
				setTableIDs(prev => [...new Set([...prev, { id, identifier }])]);
			}
		});
	}, [elements]);

	useEffect(() => {
		const fetchData = async () => {
			const tmpData = [];

			const requests = tableIDs?.map(item =>
				axios.get(
					`${REACT_APP_PATH}/Micado.WebEngine/Micado.WebEngine.Plugin.Media/GetMediaTable.api?lang=${lang}&id=${item?.id}`,
					{ identifier: item?.identifier }
				)
			);

			axios.all(requests).then(responses => {
				responses.forEach(response => {
					const { data: { content, title } = {}, config: { identifier } = {} } = response;

					tmpData?.push({ content, identifier, title });
				});

				setTables(tmpData);
			});
		};

		fetchData();
	}, [lang, REACT_APP_PATH, tableIDs]);

	const handleClick = identifier => {
		setActive(identifier);
	};

	return (
		<div className={clsx(css.root, "mco-specifications")}>
			<div className={clsx(css.top, "mco-specifications-top")}>
				{headline && (
					<Typography
						className={css.headline}
						component="h2"
						dangerouslySetInnerHTML={{ __html: headline }}
						variants={{
							xs: "headline-medium",
							lg: "headline-large"
						}}
					/>
				)}
				<Button
					color="primary"
					className={clsx(css.buttonHide, "mco-specifications-top__button-hide")}
					variant="text"
					size="small"
					endIcon={
						<ReactSVGIcon
							color="primary"
							size={16}
							src={isVisible ? "/img/icons/chevron-up.svg" : "/img/icons/chevron-down.svg"}
						/>
					}
					onClick={() => setIsVisible(!isVisible)}
				>
					{isVisible ? l("specifications.hide") : l("specifications.show")}
				</Button>
			</div>
			{isVisible && (
				<div className={clsx(css.container, "mco-specifications-container")}>
					<div className={clsx(css.buttons, "mco-specifications-top__buttons")}>
						{!isEmpty(groups) &&
							groups?.map((title, index) => {
								return (
									<div
										className={clsx("mco-specifications-top__buttons-button", {
											"mco-specifications-top__buttons-button--active": active === title
										})}
										key={index}
										onClick={() => handleClick(title)}
									>
										<Typography
											component="span"
											dangerouslySetInnerHTML={{ __html: title }}
											variants={{
												xs: "body-medium",
												lg: "headline-small"
											}}
										/>
									</div>
								);
							})}
					</div>
					<Container
						className={clsx(css.content, "mco-specifications-content")}
						maxWidth={1112}
					>
						{!isEmpty(tables) ? (
							tables?.map((item, index) => {
								const { content, identifier, title } = item;

								const newTableArray = [];

								for (const entries of content?.items) {
									const rows = entries?.items;
									const columns = rows.map(column => {
										const columnObj = {
											text: column?.text,
											align: column?.["attr-align"],
											width: column?.["attr-width"]
										};
										return columnObj;
									});

									newTableArray.push(columns);
								}

								if (identifier === active) {
									return (
										<motion.div
											animate={{ x: 0, opacity: 1 }}
											initial={{ x: -100, opacity: 0 }}
											transition={{ duration: 0.5, delay: index * 0.1 }}
											className={clsx(css.tableWrapper, "mco-specifications-content__table")}
											key={index}
										>
											<ReactTable01 items={newTableArray} title={title} sticky={false} />
										</motion.div>
									);
								}

								return null;
							})
						) : (
							<Alert severity="error">{l("specifications.noItems")}</Alert>
						)}
					</Container>
				</div>
			)}
		</div>
	);
};

export default Specifications;
