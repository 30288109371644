import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import debounce from "lodash/debounce";
import UiContext from "@UiContext";
import PageContext from "@PageContext";
import { motion } from "framer-motion";
import useScrolling from "../../../../../hooks/useScrolling";

import styles from "./Level1.styles";

const ReactLink = loadable(() => import("@micado-digital/react-link/ReactLink"));
const Typography = loadable(() => import("@micado-digital/react-ui/components/Typography"));

const Level1 = ({ currentLevel1ID, items, onClose, onItemClick }) => {
	const css = styles();
	const [animate, setAnimate] = useState(false);
	const [ui] = useContext(UiContext);
	const { desktopMenuOpen } = ui;
	const [pageData] = useContext(PageContext);
	const { layoutVariant } = pageData;
	const scrollPosition = useScrolling();
	const isScrollingTop = scrollPosition > 0;
	const isIntro = layoutVariant === "intro";

	useEffect(() => {
		setTimeout(() => {
			setAnimate(true);
		}, 125);
	}, []);

	const animationContainer = {
		animate: {
			transition: {
				delayChildren: 0.5,
				staggerChildren: 0.15
			}
		}
	};

	const animationItem = {
		initial: {
			opacity: 0,
			y: 28
		},
		animate: {
			opacity: 1,
			transition: {
				stiffness: 60,
				type: "spring"
			},
			y: 0
		}
	};

	const handleItemMouseOver = debounce((id, items) => {
		onItemClick(id, items);
	}, 200);

	return (
		<motion.div
			animate={animate && "animate"}
			className={clsx(css.level1, "mco-view-component-header-desktop-menu-level1")}
			initial="initial"
			variants={animationContainer}
		>
			{items?.map(item => {
				const { id, items, link, selected, target, title } = item;

				return (
					<motion.div
						className={clsx(css.item, "mco-view-component-header-desktop-menu-level1-item")}
						key={id}
						variants={animationItem}
					>
						<ReactLink
							className={clsx(
								css.link,
								"mco-view-component-header-desktop-menu-level1-item__link",
								{
									[css.linkSelected]: selected || currentLevel1ID === id,
									"mco-view-component-header-desktop-menu-level1-item__link--selected":
										selected || currentLevel1ID === id,
									[css.linkIntro]: isIntro && !isScrollingTop && !desktopMenuOpen,
									"mco-view-component-header-desktop-menu-level1-item__link--is-intro":
										isIntro && !isScrollingTop && !desktopMenuOpen
								}
							)}
							onMouseOver={e => {
								if (currentLevel1ID === id) return;

								if (items && items.length > 0) {
									e.preventDefault();

									handleItemMouseOver(id, items);
								} else {
									onClose();
								}
							}}
							target={target}
							to={link}
						>
							<Typography
								dangerouslySetInnerHTML={{ __html: title }}
								variants={{ xs: "label-small", lg: "label-medium" }}
							/>
						</ReactLink>
					</motion.div>
				);
			})}
		</motion.div>
	);
};

export default Level1;
