import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import UiContext from "@UiContext";
import PageContext from "@PageContext";
import loadable from "@loadable/component";
import isEmpty from "lodash/isEmpty";
import useFindMenuGroup from "@micado-digital/react-ui/hooks/useFindMenuGroup";
import Level1 from "./Level1";

import styles from "./DesktopMenu.styles";

const Level2 = loadable(() => import("./Level2"));

const DesktopMenu = () => {
	const css = styles();
	const [ui, setUi] = useContext(UiContext);
	const { desktopCurrentLevel1ID, desktopMenuOpen } = ui;
	const [pageData] = useContext(PageContext);
	const { lang } = pageData;
	const menuItems = useFindMenuGroup("Hauptmenü");
	const [level2, setLevel2] = useState({});
	const { level2Items } = level2;
	const [defaultLevel1MediaItem, setDefaultLevel1MediaItem] = useState({});
	const [level1MediaItem, setLevel1MediaItem] = useState({});

	useEffect(() => {
		if (desktopCurrentLevel1ID) {
			const level1 = menuItems?.find(item => item.id === desktopCurrentLevel1ID);
			const { media } = level1 || {};

			const mediaItem = media || {};

			setDefaultLevel1MediaItem(mediaItem);
			setLevel1MediaItem(mediaItem);
		}
	}, [desktopCurrentLevel1ID, menuItems]);

	const handleShowLevel2 = (id, items) => {
		if (id === desktopCurrentLevel1ID) {
			setUi(prevUi => ({ ...prevUi, desktopMenuOpen: false, desktopCurrentLevel1ID: null }));

			setLevel2({});

			return;
		}

		setUi(prevUi => ({ ...prevUi, desktopMenuOpen: true, desktopCurrentLevel1ID: id }));

		setLevel2({
			level2Items: items
		});
	};

	const handleClose = () => {
		setUi(prevUi => ({ ...prevUi, desktopMenuOpen: false, desktopCurrentLevel1ID: null }));

		setLevel2({});
	};

	return (
		<div className={clsx(css.desktopMenu, "mco-view-component-header-desktop-menu")}>
			<Level1
				currentLevel1ID={desktopCurrentLevel1ID}
				items={menuItems}
				onItemClick={handleShowLevel2}
				onClose={handleClose}
			/>
			{!isEmpty(level2) && desktopMenuOpen && (
				<Level2
					currentLevel1ID={desktopCurrentLevel1ID}
					defaultLevel1MediaItem={defaultLevel1MediaItem}
					items={level2Items}
					lang={lang}
					level1MediaItem={level1MediaItem}
					setLevel1MediaItem={setLevel1MediaItem}
					onClose={handleClose}
				/>
			)}
		</div>
	);
};

export default DesktopMenu;
