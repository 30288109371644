import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	header: {
		height: 72,
		left: 0,
		position: "fixed",
		right: 0,
		top: 0,
		userSelect: "none",
		zIndex: theme.zIndex.appBar + 1,
		transition: "background 300ms ease",
		"&:before": {
			background: theme.palette.common.white,
			boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.12)",
			content: "''",
			display: "block",
			height: "100%",
			left: 0,
			position: "absolute",
			right: 0,
			top: 0,
			transition: "background 300ms ease-out",
			zIndex: 2
		}
	},
	headerScrolling: {
		"&:before": {
			background: theme.palette.background.light
		}
	},
	headerMobileMenuOpen: {
		"&:before": {
			background: theme.palette.background.menu,
			borderBottom: "1px solid " + theme.palette.common.white,
			boxShadow: "none"
		}
	},
	headerIntro: {
		[theme.breakpoints.up("md")]: {
			color: theme.palette.common.white,
			height: 160,
			"&:before": {
				background: "none",
				borderBottom: "1px solid #CDD1D5",
				boxShadow: "none"
			}
		}
	},
	headerDesktopMenuOpen: {
		color: theme.palette.text.primary,
		"&:before": {
			background: "none",
			borderBottom: "1px solid " + theme.palette.common.white,
			boxShadow: "none",
			left: "33.3333333%",
			width: "66.666666%"
		}
	},
	headerBreadcrumb: {
		"& + .mco-view-component-header-animation-wrapper .mco-view-component-header-cta": {
			top: 123,
			transition: "all 300ms ease-in 300ms"
		}
	},
	container: {
		alignItems: "center",
		display: "flex",
		height: "100%"
	},
	icons: {
		alignItems: "center",
		display: "flex",
		marginLeft: "auto",
		position: "relative",
		[theme.breakpoints.up("md")]: {
			marginLeft: 0
		},
		[theme.breakpoints.up("lg")]: {
			gap: theme.spacing(2),
			padding: theme.spacing(0, 0, 0, 4),
			"&:before": {
				background: "#E3E5E8",
				content: "''",
				display: "block",
				height: 48,
				left: 0,
				position: "absolute",
				top: "50%",
				transform: "translate(0, -50%)",
				width: 1,
				zIndex: 3
			}
		}
	},
	iconsIntro: {
		[theme.breakpoints.up("lg")]: {
			"&:before": {
				height: 112
			}
		}
	},
	iconsDesktopMenuOpen: {
		[theme.breakpoints.up("lg")]: {
			"&:before": {
				background: theme.palette.common.white
			}
		}
	}
}));

export default styles;
