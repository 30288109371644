import React, { useContext } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import UiContext from "@UiContext";

import styles from "./Hamburger.styles";

const UiHamburger = loadable(async () => {
	const { Sling } = await import("hamburger-react");

	return Sling;
});

const Hamburger = () => {
	const css = styles();
	const [ui, setUi] = useContext(UiContext);
	const { mobileMenuOpen } = ui;

	const handleToggleMenu = () => {
		setUi(prevUi => ({ ...prevUi, mobileMenuOpen: !mobileMenuOpen }));
	};

	return (
		<div
			className={clsx(css.hamburger, "mco-view-component-header-hamburger")}
			onClick={handleToggleMenu}
		>
			<UiHamburger label="menu" toggled={mobileMenuOpen} size={24} />
		</div>
	);
};

export default Hamburger;
