import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	page: {
		display: "flex",
		flexDirection: "column",
		overflow: "clip",
		padding: "72px 0 0 0"
	},
	pageIntro: {
		[theme.breakpoints.up("md")]: {
			paddingTop: 0
		}
	},
	pageIFrame: {
		paddingTop: 0
	}
}));

export default styles;
