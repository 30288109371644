/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import UiContext from "@UiContext";
import PageContext from "@PageContext";
import useSystemLogo from "@micado-digital/react-ui/hooks/useSystemLogo";
import useScrolling from "../../../../hooks/useScrolling";
import ReactLink from "@micado-digital/react-link/ReactLink";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import styles from "./Logo.styles";

const Logo = () => {
	const css = styles();
	const [ui] = useContext(UiContext);
	const { desktopMenuOpen } = ui;
	const [pageData] = useContext(PageContext);
	const { lang, layoutVariant } = pageData;
	const logoObjDefault = useSystemLogo("logo");
	const logoObjIntro = useSystemLogo("logo-intro");
	const [logoObj, setLogoObj] = useState({});
	const { alt, homeLink, src } = logoObj;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const scrollPosition = useScrolling();
	const isScrollingTop = scrollPosition > 0;

	const logoObjIntroEN = { alt: "Heliotherm", homeLink: "/", src: "/img/logo-intro-en.svg" };

	useEffect(() => {
		if (layoutVariant === "intro" && !isMobile && !isScrollingTop) {
			setLogoObj(lang === "de" ? logoObjIntro : logoObjIntroEN);
		} else {
			setLogoObj(logoObjDefault);
		}
	}, [
		isMobile,
		isScrollingTop,
		lang,
		layoutVariant,
		logoObjDefault,
		logoObjIntro,
		logoObjIntroEN
	]);

	if (desktopMenuOpen) return null;

	return (
		<div className={clsx(css.logo, "mco-view-component-header-logo")}>
			<ReactLink
				className={clsx(css.link, "mco-view-component-header-logo__link")}
				to={homeLink}
			>
				<img
					alt={alt}
					className={clsx(css.image, "mco-view-component-header-logo__image", {
						[css.imageIntro]: layoutVariant === "intro" && !isScrollingTop,
						"mco-view-component-header-logo__image--is-intro":
							layoutVariant === "intro" && !isScrollingTop
					})}
					src={src}
				/>
			</ReactLink>
		</div>
	);
};

export default Logo;
